const LIFE_TM = {
  version: "LIFE TM",
  price: "375,000",
  image: "OROCH/OROCH LIFE TM.png",
  features: [
    "Motor: 1.6 L",
    "Potencia HP: 115",
    "Torque: 115lb-ft",
    "Transmisión: Manual",
    "Número de velocidades: 6",
    "Cilindrada (L): 4.0",
    "Combustible: Gasolina",
  ],
};

const ZEN_TM = {
  version: "Zen TM",
  price: "399,300",
  image: "OROCH/OROCH ZEN TM.png",
  features: [
    "Motor: 1.6 L",
    "Potencia HP: 115",
    "Torque: 115lb-ft",
    "Transmisión: Manual",
    "Número de velocidades: 6",
    "Cilindrada (L): 4.0",
    "Combustible: Gasolina",
  ],
};
const OUTSIDER_TM = {
  version: "Outsider TM",
  price: "429,700",
  image: "OROCH/OROCH OUTSIDER TM.png",
  features: [
    "Motor: 1.6 L",
    "Potencia HP: 115",
    "Torque: 115lb-ft",
    "Transmisión: Manual",
    "Número de velocidades: 6",
    "Cilindrada (L): 4.0",
    "Combustible: Gasolina",
  ],
};
const OUTSIDER_CVT = {
  version: "Outsider CTV",
  price: "510,000",
  image: "OROCH/OROCH OUTSIDER CVT.png",
  features: [
    "Motor: 1.3 L turbo",
    "Potencia HP: 154",
    "Torque: 184 lb-ft",
    "Transmisión: CVT",
    "Número de velocidades: 8 (Modo Manual)",
    "Cilindrada (L): 4.0",
    "Combustible: Gasolina",
  ],
};

const data = {
  group: "oroch",
  vehicle: "OROCH",
  title: "OROCH",
  versions: [LIFE_TM, ZEN_TM, OUTSIDER_TM, OUTSIDER_CVT],
  extras: [
    {
      title: "La mejor pick-up",
      image: "oroch/img1.jpg",
      texts: [
        "No importa si tu destino es parte de tu día a día o una nueva aventura, con tu nueva Renault OROCH, podrás hacerlo cómodamente a bordo de la primera doble cabina del segmento.",
      ],
    },
    {
      title: "La mejor SUV",
      image: "oroch/img2.jpg",
      texts: [
        "Intenta todo lo que quieras, tus recorridos en la nueva Renault OROCH, no serán pesados ni aburridos, ya que ofrece la única transmisión automática de su categoría.",
      ],
    },
    {
      title: "Viaja seguro",
      image: "oroch/img3.jpg",
      texts: [
        "La nueva Renault OROCH está equipada con todo lo que necesitas para sentirte seguro al volante, desde frenos ABS y bolsas de aire, hasta anclaje ISOFIX para los más pequeños. Todo para mantenerte a ti y a los tuyos seguros.",
      ],
    },
    {
      title: "Imagina tu mundo",
      image: "oroch/img4.jpg",
      texts: [
        "Hacer las cosas como a ti te gusta es parte de la actitud para intentarlo todo, por eso los accesorios de la nueva Renault OROCH te permiten adaptar tu estilo en cualquier situación y superar todos los obstáculos.",
      ],
    },
  ],
};

export const OROCH = {
  id: "oroch",
  title: "OROCH",
  image: "OROCH/OROCH OUTSIDER TM.png",
  to: "oroch",
  data,
};
