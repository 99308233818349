const INTENS = {
  version: "Intense",
  price: "573,200",
  image: "KOLEOS/KOLEOS_INTENS.png",
  features: [
    "Motor: 2.5L CVT",
    "Potencia HP: 171 HP",
    "Torque: 172 lb-ft",
    "Tipo de caja de cambios: CVT",
    "Número de velocidades: 6 velocidades",
    "Cilindrada (L): 2.5 L",
    "Combustible: Gasolina",
  ],
};
const ICONIC = {
  version: "Iconic",
  price: "671,800",
  image: "KOLEOS/KOLEOS_ICONIC.png",
  features: [
    "Motor: 2.5L CVT",
    "Potencia HP: 171 HP",
    "Torque: 172 lb-ft",
    "Tipo de caja de cambios: CVT",
    "Número de velocidades: 6 velocidades",
    "Cilindrada (L): 2.5 L",
  ],
};

const data = {
  group: "koleos",
  vehicle: "KOLEOS",
  title: "KOLEOS",
  versions: [INTENS, ICONIC],
  extras: [
    {
      title: "Velocidades",
      image: "transmision.png",
      texts: ["Transmisión Automática: CVT"],
    },
    {
      title: "Consumo de Gasolina / Combustible",
      image: "gasolina.png",
      texts: [
        "Carretera: 17.3 km/l (CVT)",
        "Ciudad: 12.7 km/l (CVT)",
        "Mixto: 14.4 km/l (CVT)",
      ],
    },
    {
      title: "Dimensiones",
      image: "llanta.png",
      texts: [
        "Alto: 1678 mm",
        "Ancho: 1843 mm",
        "Largo: 4672 mm",
        "Distancia entre ejes: 2705 mm",
        "Espacio entre asientos: 289 mm",
      ],
    },
  ],
};

export const KOLEOS = {
  id: "koleos",
  title: "KOLEOS",
  image: "KOLEOS/KOLEOS_ICONIC.png",
  to: "koleos",
  data,
};
