const INTENS = {
  version: "Intens TM",
  price: "338,900",
  image: "KANGOO/KANGOO.png",
  features: ["Rines acero 15"],
};

const data = {
  group: "kangoo",
  vehicle: "KANGOO",
  title: "KANGOO",
  versions: [INTENS],
  extras: [],
};

export const KANGOO = {
  id: "kangoo",
  title: "KANGOO",
  image: "KANGOO/KANGOO.png",
  to: "kangoo",
  data,
};
