const INTENSE_TM = {
  version: "Intense TM",
  price: "324,300",
  image: "STEPWAY/TM.png",
  features: [
    "Motor de 115 hp",
    "Disponible en transmisión CVT y manual de 5 velocidades",
    "Luces diurnas de LED",
    'Rines de aluminio de 16"',
    'Pantalla táctil de 7"',
    "Smatphone replication Apple Carplay® y Android Auto®",
    "Conectividad USB, auxiliar y Bluetooth",
    "Cuatro bolsas de aire",
    "Frenos con sistema antibloqueo",
    "Cámara de reversa",
  ],
};

const INTENSE_CVT = {
  version: "Intense CVT",
  price: "350,500",
  image: "STEPWAY/CVT.png",
  features: [
    "Motor de 115 hp",
    "Disponible en transmisión CVT y manual de 5 velocidades",
    "Luces diurnas de LED",
    'Rines de aluminio de 16"',
    'Pantalla táctil de 7"',
    "Smatphone replication Apple Carplay® y Android Auto®",
    "Conectividad USB, auxiliar y Bluetooth",
    "Cuatro bolsas de aire",
    "Frenos con sistema antibloqueo",
    "Cámara de reversa",
  ],
};

const data = {
  group: "stepway",
  vehicle: "STEPWAY",
  title: "STEPWAY",
  versions: [INTENSE_CVT, INTENSE_TM],
  extras: [
    {
      title: "Velocidades",
      image: "transmision.png",
      texts: ["Transmisión Manual: 5 velocidades"],
    },
    {
      title: "Consumo de Gasolina / Combustible",
      image: "gasolina.png",
      texts: [
        "Carretera: 20.3 km/l (TM)",
        "Ciudad: 14.5 km/l (TM)",
        "Mixto: 16.6 km/l (TM)",
      ],
    },
    {
      title: "Dimensiones",
      image: "llanta.png",
      texts: [
        "Alto: 1640 mm con barras de techo",
        "Ancho: 1758 mm",
        "Largo: 4068 mm",
      ],
    },
  ],
};

export const STEPWAY = {
  id: "stepway",
  title: "STEPWAY",
  image: "STEPWAY/CVT.png",
  to: "stepway",
  data,
};
