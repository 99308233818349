const INTENSE_TM = {
  version: "Intense TM",
  price: "379,300",
  image: "DUSTER/DUSTER_Intens_TM.png",
  features: [
    "Motor 1.6L 115 hp",
    "Potencia HP 115 hp",
    "Torque 115 lb-ft",
    "Tipo de caja de cambios Manual de 5 velocidades",
    "Número de velocidades 5",
    "Cilindrada 1.6 litros",
    "Combustible Gasolina",
  ],
};
const ICONIC_CVT = {
  version: "Iconic CVT",
  price: "465,100",
  image: "DUSTER/DUSTER_Iconic_CVT.png",
  features: [
    "Motor 1.3L Turbo 154 hp",
    "Potencia HP 154 hp",
    "Torque 184 lb-ft",
    "Tipo de caja de cambios CVT",
    "Número de velocidades 8",
    "Cilindrada (L) 1.3 litros Turbo",
    "Combustible Gasolina",
  ],
};

const data = {
  group: "duster",
  vehicle: "DUSTER",
  title: "Duster",
  versions: [ICONIC_CVT, INTENSE_TM],
  extras: [
    {
      title: "Velocidades",
      image: "transmision.png",
      texts: [
        "Transmisión Automática impulsional: 4 velocidades.",
        "Transmisión Manual: 6 velocidades.",
      ],
    },
    {
      title: "Consumo de Gasolina / Combustible",
      image: "gasolina.png",
      texts: [
        "Carretera: 18.6 km/l (TM) / 17.9 km/l (TA)",
        "Ciudad: 13.3 km/l (TM) / 11.9 km/l (TA)",
        "Mixto: 15.4 km/l (TM) / 14.0 km/l (TA)",
      ],
    },
    {
      title: "Dimensiones",
      image: "llanta.png",
      texts: ["Alto: 1690 mm", "Ancho: 1822 mm", "Largo: 4315 mm"],
    },
  ],
};

export const DUSTER = {
  id: "duster",
  title: "DUSTER",
  image: "DUSTER/DUSTER_Iconic_CVT.png",
  to: "duster",
  data,
};
