const INTENSE_CVT = {
  version: "Intense CVT",
  price: "321,500",
  image: "LOGAN/LOGANCVT.png",
  features: [
    "Luces diurnas de LED",
    'Rines de aluminio de 16"',
    'Pantalla táctil de 7"',
    "Smatphone replication Apple Carplay® & Android Auto®",
    "Conectividad USB, Aux & Bluetooth",
    "Frenos ABS y distribución electrónica de frenado",
    "4 Bolsas de aire",
  ],
};

const INTENSE_TM = {
  version: "Intense TM",
  price: "319,000",
  image: "LOGAN/LOGANTM.png",
  features: [
    "Luces diurnas de LED",
    'Rines de aluminio de 16"',
    'Pantalla táctil de 7"',
    "Smatphone replication Apple Carplay® & Android Auto®",
    "Conectividad USB, Aux & Bluetooth",
    "Frenos ABS y distribución electrónica de frenado",
    "4 Bolsas de aire",
  ],
};

const data = {
  group: "logan",
  vehicle: "LOGAN",
  title: "LOGAN",
  versions: [INTENSE_CVT, INTENSE_TM],
};

export const LOGAN = {
  id: "logan",
  title: "LOGAN",
  image: "LOGAN/LOGANCVT.png",
  to: "logan",
  data,
};
