const INTENSE = {
  version: "Intens",
  price: "230,100",
  image: "KWID/KWID intens.png",
  features: [
    "Motor 1.0 L",
    "Potencia HP 66",
    "Torque 69 lb-ft",
    "Transmisión: Manual",
    "Número de velocidades: 5",
    "Cilindrada (L) 1.0",
  ],
};
const ICONIC = {
  version: "Iconic",
  price: "254,100",
  image: "KWID/KWID Iconic.png",
  features: [
    "Motor 1.0 L",
    "Potencia HP 66",
    "Torque 69 lb-ft",
    "Transmisión: Manual",
    "Número de velocidades: 5",
    "Cilindrada (L) 1.0",
    "Combustible: Gasolina",
  ],
};

const BITONO = {
  version: "Bitono",
  price: "261,400",
  image: "KWID/KWID Bitono.png",
  features: [
    "Motor 1.0 L",
    "Potencia HP 66",
    "Torque 69 lb-ft",
    "Transmisión: Manual",
    "Número de velocidades: 5",
    "Cilindrada (L) 1.0",
    "Combustible: Gasolina",
  ],
};
const OUTSIDER = {
  version: "Outsider",
  price: "267,200",
  image: "KWID/KWID Outsider.png",
  features: [
    "Motor 1.0 L",
    "Potencia HP 66",
    "Torque 69 lb-ft",
    "Transmisión: Manual",
    "Número de velocidades: 5",
    "Cilindrada (L) 1.0",
    "Combustible: Gasolina",
  ],
};

export const data = {
  group: "kwid",
  vehicle: "KWID",
  title: "KWID",
  versions: [INTENSE, ICONIC, BITONO, OUTSIDER],
  extras: [
    {
      title: "Conócela a fondo",
      image: "kwid/img1.png",
      texts: [
        "Tu nueva Renault KWID está pensada para llevarte a conectar con el mundo.",
      ],
    },
    {
      title: "Diseño único",
      image: "kwid/img2.jpg",
      texts: ["Sorpréndete con cada detalle del espíritu SUV de Renault KWID."],
    },
    {
      title: "Equipada para lo que venga",
      image: "kwid/img3.jpg",
      texts: [
        "La SUV de los compactos, no solo tiene un look impecable, sino que está pensada para ofrecerte el confort que te mereces.",
      ],
    },
    {
      title: "Personaliza tu Renault KWID",
      image: "kwid/img4.jpg",
      texts: ["Descubre nuestros accesorios y siéntete más seguro."],
    },
  ],
};

export const KWID = {
  id: "kwid",
  title: "KWID",
  image: "KWID/KWID Outsider.png",
  to: "kwid",
  data,
};
